import React from 'react';

import Seo from '@components/Seo';
import Layout from '@components/Layout';

import Desktop from '@blocks/videos/video01/desktop';
import Mobile from '@blocks/videos/video01/mobile';

import {
  Container,
} from '@styles/limitado/styles';

const Video02Page = () => (
    <Layout>
      <Seo 
        title="VPS do Zero ao Avançado | Seja um especialista em infraestrutura web." 
        description="Treinamento passo a passo para você aprender administrar servidores Linux com facilidade utilizando tecnologias de ponta que grandes empresas como Netflix, Ubber e AirBnb hospedam seus serviços." 
        image="/images/limitado/desktop/Intro/video.png"
      />
      <Container>
        <Desktop />
        <Mobile />
      </Container>
    </Layout>
);

export default Video02Page;